<template>
  <div class="lg:text-xl services home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeaderBH />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center">
      <div class="lg:mt-24 md:w-1/3 lg:w-1/2 bg-service absolute top-0 right-0 w-3/5 h-full mt-12 bg-left bg-cover">
      </div>
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Pelayanan</h2>
        <p class="lg:text-base text-sm">Beranda > <span class="text-sitePurple font-bold">Pelayanan</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl px-4 pt-12 pb-8 mx-auto">
        <div class="lg:items-center flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-4">
              Operasi plastik dan tindakan estetis lainnya menjadi hal yang biasa dengan banyak pasien memilih ahli
              bedah berpengalaman untuk hasil yang optimal.
            </p>
            <p class="mb-8">
              Dengan pengalaman operasi plastik lebih dari 30 tahun, Dr Marco berdedikasi untuk membantu pasien mencapai
              tujuan estetis mereka dengan aman dengan hasil yang tampak alami.
            </p>
            <a href="tel:+6564648075"
              class="lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left">Konsultasikan dengan Dr
              Marco hari ini untuk mengetahui lebih lanjut, hubungi kami di +65 6464 8075 sekarang.</a>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-face.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Wajah</h4>
                <p class="mb-4">
                  Masalah wajah sering terjadi pada banyak pasien, karena biasanya ini adalah tanda-tanda penuaan yang
                  pertama kali terlihat. Dr Marco dilengkapi dengan peralatan, pengetahuan, dan teknik yang diperlukan
                  untuk pengencangan wajah yang meremajakan.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Beberapa prosedur wajah yang umum dilakukan oleh Dr Marco
                  adalah:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Pengencangan Wajah</li>
                  <li class="mb-1">Pengangkatan Alis dan Pipi</li>
                  <li class="mb-1">Pengencangan Alis Atas Endoskopi</li>
                  <li class="mb-1">Operasi Kelopak Mata Atas & Bawah</li>
                  <li class="mb-1">Augmentasi Pipi</li>
                  <li class="mb-1">Augmentasi Dagu</li>
                  <li class="mb-1">Operasi Hidung dan Rhinoplasty</li>
                  <li class="mb-1">Suntikan – Pengisian Kulit & Suntikan Cangkok Lemak</li>
                  <li class="mb-1">Perbaikan Daun Telinga</li>
                  <li class="mb-1">Operasi Telinga – Otoplasti</li>
                  <li>Dermabrasi Kulit</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-body.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold uppercase">Tubuh</h4>
                <p class="mb-4">
                  Terlepas dari gaya hidup dan diet sehat, genetika memainkan peran besar dalam distribusi lemak tubuh,
                  ukuran payudara, dan banyak masalah lainnya. Dengan bakat estetika dan teknik yang terlatih secara
                  diakui internasional, Dr Marco mampu memberikan pasien hasil yang memuaskan untuk mencapai bentuk yang
                  Anda inginkan.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Beberapa prosedur umum untuk tubuh yang dilakukan Dr Marco
                  adalah:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Sedot lemak – Liposculpture</li>
                  <li class="mb-1">Pengangkatan jaringan lemak berlebih pada perut – Abdominoplasty</li>
                  <li class="mb-1">Memperbaiki Diastasis Rektus</li>
                  <li class="mb-1">Augmentasi Bokong – Gluteoplasty</li>
                  <li class="mb-1">Pengangkatan Tubuh Setelah Penurunan Berat Badan</li>
                  <li class="mb-1">Bedah Endoskopi</li>
                  <li class="mb-1">Bedah Plastik Robotik</li>
                  <li class="mb-1">Peremajaan Alat Kelamin Wanita/Labiaplasty</li>
                  <li class="mb-1">Augmentasi Payudara/Pembesaran Payudara/Implan Payudara</li>
                  <li class="mb-1">Pengangkatan Implan Payudara/Eksplantasi Payudara</li>
                  <li class="mb-1">Pengecilan Payudara – Mammoplasty</li>
                  <li class="mb-1">Pengencangan Payudara – Mastopexy</li>
                  <li class="mb-1">Operasi Pengecilan Puting</li>
                  <li class="mb-1">Cangkok Lemak ke Payudara/Pembesaran Payudara Dengan Lemak</li>
                  <li>Renuvion® - Mengencangkan Kulit</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteDoctorBH />
    <SiteConsultationBH />
    <SiteFormBH />
    <SiteFooterBH />
  </div>
</template>

<script>
  import SiteHeaderBH from '@/components/SiteHeaderBH.vue'
  import SiteDoctorBH from '@/components/SiteDoctorBH.vue'
  import SiteConsultationBH from '@/components/SiteConsultationBH.vue'
  import SiteFormBH from '@/components/SiteFormBH.vue'
  import SiteFooterBH from '@/components/SiteFooterBH.vue'
  export default {
    components: {
      SiteHeaderBH,
      SiteDoctorBH,
      SiteConsultationBH,
      SiteFormBH,
      SiteFooterBH
    },
    mounted() {  
      document.title = "Dr Marco Faria Correa Plastic Surgery - Operasi Plastik";  
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  .bg-service {
    background-image: url(/images/bg-service.png);
  }
</style>